import { createTheme } from "@material-ui/core";

export const lightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#df7c32",

      light: "rgb(82,54,78)",

      contrastText: "#ffffff",
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#df7c32",

      light: "rgb(82,54,78)",

      contrastText: "#ffffff",
    },
  },
});
