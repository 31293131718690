import Utils from ".";
import { logout } from "../components/header/action";

/**
 * function to check if code matches to user invalid.
 * @param data
 */

const checkUserValidation = (data: any) => {
  if (data) {
    const { statusCode } = data,
      { sessionExpired, unauthorized, accessDenied } =
        Utils.constants.api_error_code;
    if (statusCode) {
      return (
        statusCode === sessionExpired ||
        statusCode === unauthorized ||
        statusCode === accessDenied
      );
    }
    return false;
  }
  return false;
};

const getAccessTokenUsingRefreshToken = () => {
  if (!navigator.onLine) {
    Utils.showAlert(1, "Please check your internet connection!");
    return;
  }

  if (localStorage.getItem("refreshToken") !== null) {
    const dataToSend = `?refreshToken=${localStorage.getItem("refreshToken")}`;
    Utils.api.getApiCall(
      Utils.endPoints.refreshToken,
      dataToSend,
      (respData: any) => {
        let { data } = respData;

        if (
          data.statusCode ===
          (Utils.constants.api_success_code.success ||
            Utils.constants.api_success_code.postSuccess)
        ) {
          localStorage.setItem("accessToken", data.data.accessToken);
          localStorage.setItem("refreshToken", data.data.refreshToken);

          Utils.constants.setAuthorizationToken(data.data.accessToken);

          window.location.reload();
        } else {
          logout();
        }
      },
      (_error: any) => logout()
    );
  } else {
    logout();
  }
};

const catchError = (error: any, errorCallback: Function) => {
  if (error.code === "ECONNABORTED") {
    let payload = {
      data: {
        statusCode: 408,
      },
    };
    errorCallback(payload);
  } else if (error.response) {
    let data = error.response.data;
    if (checkUserValidation(data)) {
      setTimeout(() => {
        if (localStorage.getItem("accessToken") !== null) {
          getAccessTokenUsingRefreshToken();
        }
      }, 1000);
    } else {
      errorCallback(error.response);
    }
  } else if (!error.response) {
    let payload = {
      data: {
        statusCode: "",
        message: "Please try again later",
      },
    };
    errorCallback(payload);
  }
};

/**
 *
 * @param endPoint api end point
 * @param params request data
 * @param successCallback function for handle success response
 * @param errorCallback  function for handle error response
 */
const postApiCall = (
  endPoint: string,
  params: object,
  successCallback: Function,
  errorCallback: Function
) => {
  Utils.constants.setAuthorizationToken(
    localStorage.getItem("accessToken") || ""
  );
  Utils.constants.axios
    .post(endPoint, params)
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error: any) => catchError(error, errorCallback));
};

/**
 *
 * @param endPoint api end point
 * @param params api url parameter
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */
const getApiCall = (
  endPoint: string,
  params: string,
  successCallback: Function,
  errorCallback: Function
) => {
  Utils.constants.setAuthorizationToken(
    localStorage.getItem("accessToken") || ""
  );
  Utils.constants.axios
    .get(Utils.constants.apiUrl + endPoint + params, {})
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error: any) => catchError(error, errorCallback));
};

/**
 *
 * @param endPoint api end point
 * @param params api request data
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */
const patchApiCall = (
  endPoint: string,
  params: object,
  successCallback: Function,
  errorCallback: Function
) => {
  Utils.constants.setAuthorizationToken(
    localStorage.getItem("accessToken") || ""
  );
  Utils.constants.axios
    .patch(endPoint, params)
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error: any) => catchError(error, errorCallback));
};
/**
 *
 * @param endPoint api end point
 * @param params request data
 * @param successCallback function for handle success response
 * @param errorCallback  function for handle error response
 */
const putApiCall = (
  endPoint: string,
  params: object,
  successCallback: Function,
  errorCallback: Function
) => {
  Utils.constants.setAuthorizationToken(
    localStorage.getItem("accessToken") || ""
  );
  Utils.constants.axios
    .put(endPoint, params)
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error: any) => catchError(error, errorCallback));
};

/**
 *
 * @param endPoint api end point
 * @param params api request data
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */

const deleteApiCall = (
  endPoint: string,
  params: string,
  successCallback: Function,
  errorCallback: Function
) => {
  Utils.constants.setAuthorizationToken(
    localStorage.getItem("accessToken") || ""
  );
  Utils.constants.axios
    .delete(Utils.constants.apiUrl + endPoint + params, {})

    .then((response: any) => {
      successCallback(response);
    })
    .catch((error: any) => catchError(error, errorCallback));
};

/**
 *
 * @param endPoint api end point
 * @param params request data
 * @param successCallback function for handle success response
 * @param errorCallback  function for handle error response
 */
const otpPostApiCall = (
  endPoint: string,
  params: object,
  successCallback: Function,
  errorCallback: Function
) => {
  Utils.constants.setAuthorizationToken(localStorage.getItem("otpToken") || "");
  Utils.constants.axios
    .post(endPoint, params)
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error: any) => catchError(error, errorCallback));
};

/**
 * export all function
 */
export default {
  putApiCall,
  getApiCall,
  postApiCall,
  patchApiCall,
  deleteApiCall,
  checkUserValidation,
  otpPostApiCall,
};
